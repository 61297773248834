<template>
<div>
  <v-card>
    <v-tabs v-model="filesType">
      <v-tab href="#unsubmittedFiles">Un-submitted Files</v-tab>
      <v-tab href="#submittedFiles">Submitted Files</v-tab>
      <v-tab href="#reSubmits">ReSubmits</v-tab>
      <v-tab href="#archivedFiles">Archived Files</v-tab>
    </v-tabs>
    <v-tabs-items v-model="filesType">
      <v-tab-item value="unsubmittedFiles">
        <UnSubmittedFiles></UnSubmittedFiles>
      </v-tab-item>
      <v-tab-item value="submittedFiles">
        <SubmittedFiles></SubmittedFiles>
      </v-tab-item>
      <v-tab-item value="reSubmits">
        <ReSubmits></ReSubmits>
      </v-tab-item>
      <v-tab-item value="archivedFiles">
        <ArchivedFiles></ArchivedFiles>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</div>
</template>

<script>
export default {
  name: "index",
  components: {
    UnSubmittedFiles: () => import('./UnSubmittedFiles'),
    SubmittedFiles: () => import('./SubmittedFiles'),
    ReSubmits: () => import('./ReSubmits'),
    ArchivedFiles: () => import('./ArchivedFiles'),
  },
  data() {
    return {
      loading: false,
      filesType: null,
    }
  },
}
</script>

<style scoped>

</style>